<template>
	<main class="subcategory-page" v-if="subcategory && subcategory !== null">
		<!-- <section class="section banner">
			<div class="container has-text-centered">
				<h2 class="title">
					{{ subcategory.titlu }}
				</h2>
				<button
					class="btn btn--secondary"
					title="Înapoi la pagina anterioară"
					aria-label="Înapoi la pagina anterioară"
					@click="$router.go(-1)"
				>
					Înapoi
				</button>
			</div>
		</section> -->
		<section class="section back-section py-3">
			<div class="container">
				<button
					class="btn btn--secondary"
					title="Înapoi la pagina anterioară"
					aria-label="Înapoi la pagina anterioară"
					@click="$router.go(-1)"
				>
					Înapoi
				</button>
			</div>
		</section>
		<section
			class="section section--larger"
			v-if="subcategory.products && subcategory.products.length > 0"
		>
			<div class="container">
				<h2 class="section__title has-text-centered">
					Produse {{ subcategory.titlu }}
				</h2>
				<div class="columns is-multiline">
					<div
						class=" column is-4 mb-5"
						v-for="product in sortedProducts"
						:key="product.id"
					>
						<component-product :product="product" />
					</div>
				</div>
			</div>
		</section>
		<section
			class="section section--larger"
			v-if="subcategory.articles && subcategory.articles.length > 0"
		>
			<div class="container">
				<h2 class="section__title has-text-centered">
					Articole {{ subcategory.titlu }}
				</h2>
				<div class="columns is-multiline is-centered">
					<div
						class="column is-4 mb-5"
						v-for="article in subcategory.articles"
						:key="article.id"
					>
						<component-article
							:article="article"
						></component-article>
					</div>
				</div>
			</div>
		</section>
		<component-videos
			:videos="subcategory.videoclipuri"
			v-if="
				subcategory.videoclipuri && subcategory.videoclipuri.length > 0
			"
		/>
		<!-- <testimonial-carousel /> -->
		<component-gallery
			:images="images"
			v-if="
				subcategory.galerie_imagini &&
					subcategory.galerie_imagini.length > 0
			"
		/>
	</main>
</template>

<script>
	import ComponentProduct from '@/components/ComponentProduct.vue';
	import ComponentArticle from '@/components/ComponentArticle.vue';
	// import TestimonialCarousel from '@/components/TheTestimonialCarousel.vue';
	import ComponentVideos from '@/components/ComponentVideos.vue';
	import ComponentGallery from '@/components/ComponentGallery.vue';

	import { mapActions, mapState } from 'vuex';
	export default {
		metaInfo() {
			return {
				title: `Aloe Vera Products - ${this.subcategory.titlu}`
			};
		},
		components: {
			ComponentProduct,
			ComponentArticle,
			// TestimonialCarousel,
			ComponentVideos,
			ComponentGallery,
		},
		created() {
			this.getSubcategory(this.$route.params.subcategorie);
		},
		mounted() {
			// Trigger prerendering
			if (typeof window !== 'undefined') {
				window.dispatchEvent(new Event('prerender-trigger'));
			}
  		},
		data() {
			return {};
		},
		computed: {
			...mapState('subcategory', ['subcategory']),
			images() {
				return this.category.galerie_imagini.map(
					(img) =>
						`${process.env.VUE_APP_STRAPI_DATABASE_URL}${img.imagine.url}`
				);
			},
			sortedProducts() {
				let products = JSON.parse(JSON.stringify(this.subcategory.products)).sort((a, b) => a.pozitie - b.pozitie);
				return products;
			},
		},
		methods: {
			...mapActions('subcategory', ['getSubcategory']),
		},
	};
</script>

<style scoped lang="scss">
	.banner {
		background: linear-gradient(
			to bottom,
			lighten($gray, 10%),
			lighten($gray, 40%)
		);
		.title {
			color: $primary;
			font-size: 1.25rem;
			letter-spacing: 1px;
			text-transform: uppercase;
			text-align: center;
			@media (min-width: 481px) {
				font-size: 1.5rem;
			}
			@media (min-width: 769px) {
				font-size: 2rem;
			}
		}
	}
</style>
